import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';

export class BackupAssetLinkedEntityModel extends BaseEntityModel {
    @FormControl({
        label: '备件编号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    assetNo: string = undefined;

    @FormControl({
        label: '备件名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    assetName: string = undefined;

    @FormControl({
        label: '规格型号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    modelStatus: string = undefined;

    @FormControl({
        label: '品牌',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    positionName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '备件编号',
                dataIndex: 'assetNo',
                scopedSlots: { customRender: 'assetNo' }
            },
            {
                title: '备件名称',
                dataIndex: 'assetName',
                scopedSlots: { customRender: 'assetName' }
            },
            {
                title: '规格型号',
                dataIndex: 'modelStatus',
                scopedSlots: { customRender: 'modelStatus' }
            },
            {
                title: '品牌',
                dataIndex: 'positionName',
                scopedSlots: { customRender: 'positionName' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}
export class BackupAssetLinkedQueryModel extends QueryPageModel {
    constructor(id: string) {
        super();
        this.id = id;
    }
    id: string = undefined;
}
