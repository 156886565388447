
import { BackupAssetEntityModel, BackupAssetQueryModel } from '@common-src/entity-model/backup-asset-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${ASSET_BASE_REQUEST_PATH}/backupAsset`;

export class BackupAssetService implements ICRUDQ<BackupAssetEntityModel, BackupAssetQueryModel> {
    async create(model: BackupAssetEntityModel):Promise<BackupAssetEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    // async retrieve(modelId: string):Promise<BackupAssetEntityModel> {
    //     const url = `${URL_PATH}/detail/${modelId}`;
    //     const res = await get(url);
    //     return new BackupAssetEntityModel().toModel(res);
    // }
    async retrieve(modelId: string):Promise<BackupAssetEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return res;
        // return new AssetEntityModel().toModel(res);
    }

    async update(model: BackupAssetEntityModel):Promise<BackupAssetEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: BackupAssetEntityModel):Promise<BackupAssetEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: BackupAssetQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new BackupAssetEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new BackupAssetEntityModel().toModel(item));
    }
    async getBatchList(id: string, query?: BackupAssetQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/batchList/${id}`;
        const res = await get(url);
        // return _.map(res, item => item = new FacilityEntityModel(this.type).toModel(item));
        return res;
    }

    async getLinkedAssetList(id: string):Promise<any> {
        const params = { page: 1, limit: 100 };
        const url = `${URL_PATH}/getLinkedAsset/${id}`;
        const res = await post(url, params);
        return res;
        // return _.map(res, item => item = new FacilityEntityModel(this.type).toModel(item));
    }
    // async link(id: string, parentId: string):Promise<any> {
    //     const url = `${URL_PATH}/link`;
    //     return await post(url, { childId: id, parentId: parentId });
    // }
    async link(parentId: string, childId: Array<string>):Promise<any> {
        const url = `${URL_PATH}/link`;
        return await post(url, { id: parentId, children: childId });
    }
}

// export default new BackupAssetService();
