var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c("div", { staticClass: "asset-detail-component table-component" }, [
        _c(
          "div",
          { staticClass: "page-body page-body-bg-white" },
          [
            _c(
              "a-tabs",
              {
                attrs: { type: "card", "default-active-key": "1" },
                on: { change: _vm.changeTab },
                model: {
                  value: _vm.tabIndex,
                  callback: function ($$v) {
                    _vm.tabIndex = $$v
                  },
                  expression: "tabIndex",
                },
              },
              [
                _c(
                  "a-tab-pane",
                  { key: "1", attrs: { tab: "备件信息" } },
                  [
                    _c(
                      "a-descriptions",
                      { staticClass: "tab-margin-16", attrs: { bordered: "" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _c(
                              "jtl-button",
                              {
                                staticClass: "description-title-button",
                                attrs: { type: "primary" },
                                on: { click: _vm.editClick },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "备件编号", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.code) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "创建时间", span: 2 } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.backupAssetModel.createdTime
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "备件分组", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.groupName) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "规格型号", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.type) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "计量单位", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.unit) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "数量", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.count) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "品牌", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.brand) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供应商", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.supplier) + " "
                            ),
                          ]
                        ),
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "供应商电话", span: 2 } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.backupAssetModel.phone) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload },
      }),
      _c("backup-asset-link-dialog", {
        ref: "formLinkDialog",
        on: { dialogOK: _vm.reloadLinked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }